import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '../components/Seo/Seo';

import './index.css';

function NotFoundPage() {
  return (
    <>
      <Seo title="404: No encontrado." />
      <div className="error text-center">
        <div className="row">
          <div className="col-sm-12">
            <StaticImage
              src="../../static/images/404.png"
              className="error-img"
              placeholder="blurred"
              loading="eager"
              quality={95}
              formats={['auto', 'webp', 'avif']}
              alt="Colections Image"
              width={300}
            />
            <h1>
              <strong>404</strong>
            </h1>
            <p>La página que estás buscando no existe o fué borrada.</p>
            <a href="/" className="btn btn-primary">
              {' '}
              &#8592; Volver a la Home
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFoundPage;
